import axios from 'axios';
import {API_ENDPOINT} from '../config/constants';
import qs from 'querystring';

export default new (class Request {
  constructor() {
    ['get', 'post', 'put', 'patch', 'delete'].forEach(method => {
      this[method] = async (endpoint, data, isQueryString = false) => await Request.request(method, endpoint, data, isQueryString);
    });
  }

  static async request(method, endpoint, data, isQueryString) {
    method = method.toLowerCase();

    try {
      const stringified = qs.stringify(data);
      const queryString = ((method === 'get' || isQueryString) && stringified) ? ('?' + stringified) : '';

      if (Array.isArray(endpoint)) {
        let [url, getData] = endpoint;

        getData = Object.keys(getData)
          .filter(key => typeof getData[key] !== 'undefined')
          .reduce((acc, item) => Object.assign({}, acc, {[item]: getData[item]}), {});

        endpoint = url + '?' + qs.stringify(getData);
      }

      const response = await axios({
        method,
        baseURL: API_ENDPOINT,
        url: endpoint + queryString,
        data: method !== 'get' ? data : undefined
      });

      return response.data;
    } catch (e) {
      console.error(`[${method.toUpperCase()} ${endpoint}] Failed. ${e.message}`);
      return e;
    }
  }
})();
