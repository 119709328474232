import React, {Component} from 'react';
import {injector} from 'react-services-injector';
import classnames from 'classnames';
import {Link} from 'react-router-dom';
import Picture from '../../shared/picture';
import styles from './index.module.scss';
import Inventor from '../screensaver/inventor';
import {isMobile, SLIDE_QUEUE_INTERVAL_MS, SWIPE_VIEW_WIDTH_PERCENT_THRESHOLD} from '../../../config/constants';
import {withRouter} from 'react-router-dom';

class Slider extends Component {
  constructor() {
    super();

    this.state = {
      year: null,
      swipe: false,
      initialX: null,
    }
  }

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {APIService} = this.services;

    if (!APIService.loading && !this.state.year) {
      this.setState({year: this.getSortedInventions()[0].invention_year});
    }
  }

  getCurrentYearIndex() {
    const sortedInventions = this.getSortedInventions();
    const {current} = this.props;

    return sortedInventions.findIndex(it => current ? current.toString() === it.id.toString() : it.invention_year === this.state.year);
  }

  getSortedInventions() {
    const {APIService} = this.services;

    return APIService.inventions.sort((a, b) => parseInt(a.invention_year) - parseInt(b.invention_year));
  }

  generateSlideQueue(newIndex) {
    const queue = [];
    const sortedInventions = this.getSortedInventions();
    const lastIndex = sortedInventions.length - 1;
    const currentYearIndex = this.getCurrentYearIndex();

    if (newIndex === currentYearIndex)
      return queue;

    if (newIndex > currentYearIndex) {
      new Array(newIndex - currentYearIndex).fill(null).forEach((_, index) => {
        queue.push(currentYearIndex + index + 1);
      });
    } else {
      new Array(currentYearIndex - newIndex).fill(null).forEach((_, index) => {
        queue.push(currentYearIndex - (index + 1));
      });
    }

    return queue.reverse();
  }

  slide(directionOrIndex, forceIndex = false) {
    const sortedInventions = this.getSortedInventions();
    const yearIndex = sortedInventions.findIndex(it => it.invention_year === this.state.year);

    if (forceIndex) {
      let newIndex = this.state.initialIndex + directionOrIndex;

      if (newIndex >= sortedInventions.length)
        newIndex -= sortedInventions.length;

      if (newIndex < 0)
        newIndex += sortedInventions.length;

      return this.setState({year: sortedInventions[newIndex].invention_year});
    }

    if (yearIndex === 0 && directionOrIndex === -1)
      return this.setState({year: sortedInventions[sortedInventions.length - 1].invention_year});

    if (yearIndex === (sortedInventions.length - 1) && directionOrIndex === 1)
      return this.setState({year: sortedInventions[0].invention_year});

    this.setState({year: sortedInventions[yearIndex + directionOrIndex].invention_year});
  }

  goToYear(index, forceGo) {
    const sortedInventions = this.getSortedInventions();

    if (forceGo) {
      return this.props.history.push(`/details/${sortedInventions[index].id}`);
    }

    if (isMobile(document)) {
      return this.setState({year: sortedInventions[index].invention_year});
    }

    const slideQueue = this.generateSlideQueue(index);

    const slideInterval = setInterval(() => {
      if (slideQueue.length === 0) {
        return clearInterval(slideInterval);
      }

      const newYear = sortedInventions[slideQueue.pop()].invention_year; // HAPPY NEW YEAR
      this.setState({year: newYear});
    }, SLIDE_QUEUE_INTERVAL_MS);
  }

  startSwipe(e) {
    const sortedInventions = this.getSortedInventions();
    const yearIndex = sortedInventions.findIndex(it => it.invention_year === this.state.year);

    this.setState({swipe: true, initialIndex: yearIndex, initialX: e.touches[0].pageX});
  }

  stopSwipe() {
    this.setState({swipe: false});
  }

  doSwipe(e) {
    const {swipe, initialX} = this.state;

    if (!swipe)
      return;

    const viewWidth = document.body.offsetWidth;
    const currentX = e.touches[0].pageX;
    const difference = initialX - currentX;

    const percent = Math.abs(difference) / viewWidth * 100;

    if (percent > SWIPE_VIEW_WIDTH_PERCENT_THRESHOLD) {
      const cardsSwipedCount = Math.floor(percent / SWIPE_VIEW_WIDTH_PERCENT_THRESHOLD) * (currentX > initialX ? -1 : 1);

      this.slide(cardsSwipedCount, true);
    }
  }

  render() {
    const {APIService} = this.services;
    const {small} = this.props;

    if (APIService.loading)
      return null;

    const sortedInventions = this.getSortedInventions();
    const yearIndex = this.getCurrentYearIndex();

    return (
      <div className={styles.container}
        onTouchStart={e => this.startSwipe(e)}
        onTouchEnd={e => this.stopSwipe(e)}
        onTouchMove={e => this.doSwipe(e)}
      >
        {!small && (
          <div className={styles.slider}>
            <div className={styles.left} onClick={() => this.slide(-1)}>
              <Picture name="arrow-left" />
            </div>

            <div className={styles.right} onClick={() => this.slide(1)}>
              <Picture name="arrow-right" />
            </div>

            {sortedInventions.map((it, index) => (
              <div key={it.id} className={styles.card}>
                <Link to={`/details/${it.id}`}>
                  <Inventor
                    slide
                    sliding={this.state.swipe}
                    center={this.state.year === it.invention_year}
                    left={index === yearIndex - 1 || (yearIndex === 0 && index === sortedInventions.length - 1)}
                    veryLeft={index === yearIndex - 2 || (yearIndex === 0 && index === sortedInventions.length - 2) || (yearIndex === 1 && index === sortedInventions.length - 1)}
                    right={index === yearIndex + 1 || (yearIndex === sortedInventions.length - 1 && index === 0)}
                    veryRight={index === yearIndex + 2 || (yearIndex === sortedInventions.length - 2 && index === 0) || (yearIndex === sortedInventions.length - 1 && index === 1)}
                    inventor={APIService.inventors.find(inventor => inventor.invention[0].toString() === it.id.toString())}
                  />
                </Link>
              </div>
            ))}
          </div>
        )}

        <div className={classnames(styles.years, {[styles.small]: small})}>
          {!small && (
            <div className={styles.yearsTitle}>
              {isMobile(document) ? 'Choose from 12 inventors' : 'Year of invention'}
            </div>
          )}
          {
            small &&  (
              <p>Select a different year:</p>
            )
          }

          <div className={styles.yearsContainer}>
            {[
              isMobile(document) && yearIndex === 0 && sortedInventions[sortedInventions.length - 1],
              ...sortedInventions,
              isMobile(document) && yearIndex === sortedInventions.length - 1 && sortedInventions[0],
            ].filter(Boolean).map((it, _arrayIndex) => {
              const index = sortedInventions.findIndex(invention => invention.invention_year === it.invention_year);

              return (
                <div
                  className={classnames(styles.year, {
                    [styles.current]: index === yearIndex,
                    [styles.previous]: index === yearIndex - 1 || (isMobile(document) && yearIndex === 0 && _arrayIndex === 0),
                    [styles.next]: index === yearIndex + 1 || (isMobile(document) && yearIndex === sortedInventions.length - 1 && _arrayIndex === sortedInventions.length),
                    [styles.visible]: (
                      (index >= yearIndex - 2 && index <= yearIndex + 2) || 
                      (yearIndex >= sortedInventions.length - 2 && index > sortedInventions.length - 6) ||
                      (yearIndex <= 2 && index < 5)
                    ),
                  })}
                  key={_arrayIndex}
                  onClick={() => this.goToYear(index, small)}
                  style={index === yearIndex ? {background: (APIService.inventors.find(inventor => inventor.invention[0].toString() === it.id.toString()) || {}).inventor_color} : undefined}
                >
                  {it.invention_year}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(injector.connect(Slider, {
  toRender: ['APIService'],
}));
