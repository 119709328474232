import {Service} from 'react-services-injector';
import request from '../helpers/request';

class APIService extends Service {
  constructor() {
    super();

    this.data = {
      inventors: [],
      inventions: [],
      pages: [],
      loading: true,
    };
  }

  serviceDidConnect() {
    this.fetchData();
  }

  get loading() {
    return this.data.loading;
  }

  get inventors() {
    return this.data.inventors;
  }

  get inventions() {
    return this.data.inventions;
  }

  get dashboard() {
    return this.data.pages.filter(p => p.slug === 'dashboard')[0]
  }

  findInvention(id) {
    return this.data.inventions.find(it => it.id.toString() === id.toString());
  }

  fetchData() {
    this.data.loading = true;

    Promise.all([
      request.get('/inventors', {per_page: 12})
        .then(data => this.data.inventors = data),
      request.get('/inventions', {per_page: 12})
        .then(data => this.data.inventions = data),
        request.get('/pages')
        .then(data => this.data.pages = data),
    ]).then(() => {
      this.data.loading = false;
      this.$update();
    }).catch(err=>{
      console.log(err)
    });
  }
}

APIService.publicName = 'APIService';
export default APIService;
