import React, {Component} from 'react';
import classnames from 'classnames';
import {injector} from 'react-services-injector';
import styles from './index.module.scss';
import Slider from './slider';
import {INACTIVE_TIMEOUT_MS} from '../../../config/constants';
import {withRouter} from 'react-router-dom';
import captureIdle from '../../../helpers/captureIdle';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.captureIdle = captureIdle(this, this.captureInactive);
  }

  componentDidMount() {
    this.captureIdle.start();
  }

  componentWillUnmount() {
    this.captureIdle.stop();
  }

  captureInactive() {
    this.props.history.push('/');
  }

  getTexturePositions(side = 'left') {
    const {APIService}  = this.services,
          top = APIService.dashboard[`dashboard_${side}_texture_top`],
          right = APIService.dashboard[`dashboard_${side}_texture_right`],
          bottom = APIService.dashboard[`dashboard_${side}_texture_bottom`],
          left = APIService.dashboard[`dashboard_${side}_texture_left`];

    return{
      'top': (top === 'auto') ? top : `${top}%`,
      'right': (right === 'auto') ? right : `${right}%`,
      'bottom': (bottom === 'auto') ? bottom : `${bottom}%`,
      'left': (left === 'auto') ? left : `${left}%`,
    };
  }

  render() {
    const {APIService}  = this.services;

    if (APIService.loading)
    return 'Loading...';

    const {
      dashboard_text,
      dashboard_left_texture_image,
      dashboard_right_texture_image,
      dashboard_headline_1,
      dashboard_headline_2,
      dashboard_headline_3,
      dashboard_headline_4
    } = APIService.dashboard;

    return (
      <div className={styles.dashboard}>
        <img src={dashboard_left_texture_image} alt="" className={styles.leftTexture} style={this.getTexturePositions()}/>
        <img src={dashboard_right_texture_image} alt="" className={styles.rightTexture} style={this.getTexturePositions('right')} />
        <div className={styles.dashboardWrapper}>
          <div className={styles.head}>
            <div className={classnames(styles.separator, styles.top)} />

            <div className={styles.title}>
              {dashboard_headline_1 && <div className={styles.text}>{dashboard_headline_1}</div>}
              {dashboard_headline_2 && <div className={styles.big1}>{dashboard_headline_2}</div>}
              {dashboard_headline_3 && <div className={styles.big2}>{dashboard_headline_3}</div>}
              {dashboard_headline_4 && <div className={styles.text}>{dashboard_headline_4}</div>}
            </div>

            <div className={classnames(styles.separator, styles.bottom)} />
          </div>

          <div className={styles.dashboardContent} dangerouslySetInnerHTML={{__html: dashboard_text}} />

          <Slider />
        </div>
      </div>
    );
  }
}

export default withRouter(injector.connect(Dashboard, {
  toRender: ['APIService'],
}));
