export const API_ENDPOINT                       = process.env.REACT_APP_API_ENDPOINT;
export const FLIP_INTERVAL_MS                   = process.env.REACT_APP_FLIP_INTERVAL_MS;
export const FLIP_KEEP_BACKFACE_MS              = process.env.REACT_APP_FLIP_KEEP_BACKFACE_MS;
export const SWIPE_VIEW_WIDTH_PERCENT_THRESHOLD = process.env.REACT_APP_SWIPE_VIEW_WIDTH_PERCENT_THRESHOLD;
export const SLIDE_QUEUE_INTERVAL_MS            = process.env.REACT_APP_SLIDE_QUEUE_INTERVAL_MS;
export const MOVE_DIRECTION_MAX_ANGLE           = process.env.REACT_APP_MOVE_DIRECTION_MAX_ANGLE;
export const MOVE_DIRECTION_MIN_ANGLE           = process.env.REACT_APP_MOVE_DIRECTION_MIN_ANGLE;
export const MOVE_INTERVAL_MS                   = process.env.REACT_APP_MOVE_INTERVAL_MS;
export const INACTIVE_TIMEOUT_MS                = process.env.REACT_APP_INACTIVE_TIMEOUT_MS;
export const IS_MOBILE_VIEW_WIDTH               = process.env.IS_MOBILE_VIEW_WIDTH;


export const isMobile = (_document) => _document.body.offsetWidth <= IS_MOBILE_VIEW_WIDTH;
