import {INACTIVE_TIMEOUT_MS} from '../config/constants';

const PREFIX = '__captureIdle';
const CHECK_INTERVAL_MS = 5000;
const events = ['click', 'scroll', 'touchstart', 'keydown'];

const captureIdle = (context, fn) => {
  const updateLastActivity = () => {
    context[PREFIX + 'lastActivity'] = new Date().getTime();
  };

  let checkInterval = -1;

  return {
    start: () => {
      updateLastActivity();

      events.forEach(event => {
        document.addEventListener(event, updateLastActivity);
      });

      checkInterval = setInterval(() => {
        const now = new Date().getTime();

        if ((now - context[PREFIX + 'lastActivity']) >= INACTIVE_TIMEOUT_MS) {
          fn.bind(context)();
        }
      }, CHECK_INTERVAL_MS);
    },
    stop: () => {
      context[PREFIX + 'lastActivity'] = null;
      clearInterval(checkInterval);

      events.forEach(event => {
        document.removeEventListener(event, updateLastActivity);
      });
    },
  };
}

export default captureIdle;
