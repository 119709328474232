import React, {Component} from 'react';

class Picture extends Component {
  constructor(props) {
    super(props);

    this.state = {hover: false};
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.ref !== prevState.ref) {
      this.applyListeners();
    }
  }

  applyListeners(props = this.props) {
    if (props.hoverDepth && props.hover !== false) {
      let parent = this.state.ref.parentNode;
      for (let i = props.hoverDepth; i > 1; i--) {
        parent = parent.parentNode;
      }

      parent.addEventListener('mouseenter', () => this.setState({hover: true}));
      parent.addEventListener('mouseleave', () => this.setState({hover: false}));
    }
  }

  render() {
    if (!this.props.name)
      return null;

    const {hoverDepth, hover, name, ext, disable, size, ...other} = this.props;
    const hoverEvents = !hoverDepth && hover !== false;

    const imageName = `${name}${this.state.hover && !disable ? '-hover' : ''}`;
    const imageExt = ext || 'png';
    let picture;

    try {
      picture = require(`../../../images/${imageName}.${imageExt}`);
    } catch (e) {
      picture = require(`../../../images/${name}.${imageExt}`);
    }

    return (
      <img
        className={this.props.className}
        ref={ref => ref && ref !== this.state.ref && this.setState({ref})}
        src={picture}
        alt={name}
        width={size}
        height={size}
        onMouseEnter={hoverEvents ? (() => this.setState({hover: true})) : undefined}
        onMouseLeave={hoverEvents ? (() => this.setState({hover: false})) : undefined}
        {...other}
      />
    );
  }
}

export default Picture;
