import React, {Component} from 'react';
import {injector} from 'react-services-injector';
import {Link} from 'react-router-dom';
import Inventor from './inventor';
import styles from './index.module.scss';
import {
  FLIP_INTERVAL_MS,
  FLIP_KEEP_BACKFACE_MS,
  MOVE_DIRECTION_MAX_ANGLE, MOVE_DIRECTION_MIN_ANGLE,
  MOVE_INTERVAL_MS
} from '../../../config/constants';
import {withRouter} from 'react-router-dom';

class Screensaver extends Component {
  constructor() {
    super();

    this.startButtonRef = null;
    this.moveIntervalId = null
    this.rotateIntervalId = null;
    this.queue1 = [];
    this.queue2 = [];

    this.state = {
      rotated: [],
    };
  }

  componentDidMount() {
    this.setState({rotated:[]})
  }

  componentDidUpdate() {
    const {APIService} = this.services;

    if (!this.rotateIntervalId && !APIService.loading) {
      this.initializeQueue();
      this.flipCards();
      
      this.rotateIntervalId = setInterval(() => {
        this.flipCards();
      }, FLIP_INTERVAL_MS);
    }
  }

  flipCards() {
    if (this.queue1.length === 0 || this.queue2.length === 0)
    this.initializeQueue();

    const randomIndex1 = Math.floor(Math.random() * this.queue1.length);
    const randomIndex2 = Math.floor(Math.random() * this.queue2.length);

    this.setState({rotated: [this.queue1[randomIndex1], this.queue2[randomIndex2]]});
    
    this.queue1.splice(randomIndex1, 1);
    this.queue2.splice(randomIndex2, 1);

    setTimeout(() => {
      this.setState({rotated: []});
    }, FLIP_KEEP_BACKFACE_MS); 

  }

  initializeQueue() {
    const {APIService} = this.services;
    const halfLength = Math.floor(APIService.inventors.length / 2);
    const isEven = APIService.inventors.length % 2 === 0;

    this.queue1 = new Array(halfLength).fill(null).map((_, index) => index);
    this.queue2 = new Array(isEven ? halfLength : halfLength + 1).fill(null).map((_, index) => halfLength + index);
  }

  render() {
    const {APIService} = this.services;

    if (APIService.loading)
      return 'Loading...';

    return (
      <Link to="/dashboard" className={styles.screensaver}>
        <div
          className={styles.startButton}
          ref={ref => ref && (this.startButtonRef = ref)}
        >
          Touch anywhere to start
        </div>
        {APIService.inventors
          .sort((a, b) => {
            if(a.screensaver_inventor_order === null) {
              a.screensaver_inventor_order = 0
            }

            if(b.screensaver_inventor_order === null) {
              b.screensaver_inventor_order = 0
            }
       
            return a.screensaver_inventor_order - b.screensaver_inventor_order
          })
          .map((inventor, index) => (
            <Inventor key={index} inventor={inventor} rotated={this.state.rotated.includes(index)} />
          ))}
      </Link>
    );
  }
}

export default withRouter(injector.connect(Screensaver, {
  toRender: ['APIService'],
}));
