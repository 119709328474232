import React from 'react';
import Screensaver from './components/pages/screensaver'
import Dashboard from './components/pages/dashboard'
import Detailed from './components/pages/detailed'
import {Switch, Route} from 'react-router-dom';
import {isMobile} from './config/constants';

class App extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path='/' component={isMobile(document) ? Dashboard : Screensaver}/>
        <Route path='/dashboard' component={Dashboard}/>
        <Route path='/details/:id' component={Detailed}/>
      </Switch>
    );
  }
}

export default App;
