import React from 'react';
import {injector} from 'react-services-injector';
import classnames from 'classnames';
import styles from './index.module.scss';

class Inventor extends React.Component {
  getColorFor(type = 'backgroundColor') {
    const {inventor_color} = this.props.inventor;

    return {
      [type]: inventor_color,
    };
  }

  getTexturePositions() {
   const landscape = window.matchMedia("(orientation: landscape)").matches;
   const {
      inventor_texture_top,
      inventor_texture_right,
      inventor_texture_bottom,
      inventor_texture_left,
      inventor_texture_top_desktop,
      inventor_texture_right_desktop,
      inventor_texture_bottom_desktop,
      inventor_texture_left_desktop,
   } = this.props.inventor;

   if(landscape && !this.props.slide) {
      return {
        'top': inventor_texture_top_desktop ? `${inventor_texture_top_desktop}%` : 'auto',
        'right': inventor_texture_right_desktop ? `${inventor_texture_right_desktop}%` : 'auto',
        'bottom': inventor_texture_bottom_desktop ? `${inventor_texture_bottom_desktop}%` : 'auto',
        'left': inventor_texture_left_desktop ? `${inventor_texture_left_desktop}%` : 'auto',
      }
   }

   return {
      'top': inventor_texture_top ? `${inventor_texture_top}%` : 'auto',
      'right': inventor_texture_right ? `${inventor_texture_right}%` : 'auto',
      'bottom': inventor_texture_bottom ? `${inventor_texture_bottom}%` : 'auto',
      'left': inventor_texture_left ? `${inventor_texture_left}%` : 'auto',
    }
  }

  render() {
    const {APIService} = this.services;

    if (!this.props.inventor)
      return null;

    const {
      inventor_photo,
      inventors_bio,
      inventor_texture_image,
      title,
      invention,
      photo_position_side,
      inventor_text_align,
      mirrored_image,
    } = this.props.inventor;

    const [inventionId] = invention;
    const inventionData = APIService.findInvention(inventionId);

    return (
      <div className={classnames(styles.inventor, {
        [styles.rotated]: this.props.rotated,
        [styles.slide]: this.props.slide,
        [styles.sliding]: this.props.sliding,
        [styles.center]: this.props.center,
        [styles.big1]: this.props.left,
        [styles.big2]: this.props.right,
        [styles.small1]: this.props.veryLeft,
        [styles.small2]: this.props.veryRight,
      })}>
        <div className={styles.frontface} style={this.getColorFor()}>
          <div className={classnames(styles.names, {[styles.long]: title.rendered.length > 18})} dangerouslySetInnerHTML={{__html: title.rendered}}/>

          <div className={classnames(styles.inventionTitle,{
            [styles.alignedRight]: inventor_text_align[0] === 'Right',
          })}>
            Invented {inventionData.title.rendered}
          </div>

          <img style={this.getTexturePositions()}
            className={styles.texture} src={inventor_texture_image} alt=""
          />
          <img className={classnames(styles.photo, styles.touchscreen, {
            [styles.leftside]: photo_position_side[0] === "Left",
            [styles.rightside]: photo_position_side[0] === "Right",
            })}
            src={inventor_photo}
            alt={inventors_bio.map(it => it.inventor_name).join(', ')}
          />

          <img className={classnames(styles.photo, styles.desktop)}
              src={
                mirrored_image === null || mirrored_image.length === 0 ?
                  inventor_photo :
                  mirrored_image
              }
              alt={inventors_bio.map(it => it.inventor_name).join(', ')}
          />
        </div>

        <div className={styles.backface}>
          <div style={this.getColorFor('color')} className={classnames(styles.names, {[styles.long]: title.rendered.length > 20})}  dangerouslySetInnerHTML={{__html: title.rendered}} />
          <div className={styles.inventionTitle}>
            Invented {inventionData.title.rendered}
          </div>
        </div>
      </div>
    );
  }
}

export default injector.connect(Inventor, {
  toRender: []
});
