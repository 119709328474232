import React, {Component} from 'react';
import {injector} from 'react-services-injector';
import classnames from 'classnames';
import styles from './index.module.scss';
import Picture from '../../shared/picture';
import {isMobile} from '../../../config/constants';
import {Link} from 'react-router-dom';
import Slider from '../dashboard/slider';
import {withRouter} from 'react-router-dom';
import captureIdle from '../../../helpers/captureIdle';
import * as Scroll from 'react-scroll';
import {animateScroll as scroll} from 'react-scroll';

class Detailed extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.match.params.id,
      slide: 0,
      bioSlide: 0,
      isScrollVisible: false,
    };

    this.captureIdle = captureIdle(this, this.captureInactive);
  }

  componentDidMount() {
    this.captureIdle.start();

    if(this.biography) {
      this.checkScrollVisibility(false);
    }
  }

  componentWillUnmount() {
    this.captureIdle.stop();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.setState({
        id: this.props.match.params.id,
        slide: 0,
        bioSlide: 0,
      });
    }

    this.checkScrollVisibility(prevState.isScrollVisible);
  }
  
  checkScrollVisibility(isScrollVisible) {    
    const inventonBlock = this.biography;
    
    if( inventonBlock.scrollHeight > inventonBlock.offsetHeight && isScrollVisible === false) {
      this.setState({ isScrollVisible: true })
    } 
    if( inventonBlock.scrollHeight <= inventonBlock.offsetHeight && isScrollVisible === true) {
      this.setState({ isScrollVisible: false })
    }
  }

  captureInactive() {
    this.props.history.push('/');
  }

  scrollUp() {
    scroll.scrollMore(-300, {
      containerId: 'biography',
      smooth: 'easeInOutQuad',
    });
  }

  scrollDown() {
    scroll.scrollMore(300, {
      containerId: 'biography',
      smooth: 'easeInOutQuad',
    });
  }

  slidePatent(direction) {
    const {APIService} = this.services;
    
    const invention = APIService.inventions.find(it => it.id.toString() === this.state.id);

    this.slide(direction, 'slide', invention.patent_slider);
  }

  slideBio(direction, force) {
    const {APIService} = this.services;
    const inventor = APIService.inventors.find(it => it.invention.includes(this.state.id.toString()));

    if (force) {
      return this.setState({bioSlide: direction});
    }

    this.slide(direction, 'bioSlide', inventor.inventors_bio);
  }

  slide(direction, field, array) {
    let newIndex = this.state[field] + direction;

    if (newIndex < 0)
      newIndex = array.length - 1;

    if (newIndex >= array.length)
      newIndex = 0;

    this.setState({[field]: newIndex});
  }

  render() {
    const {APIService} = this.services;

    if (APIService.loading)
      return null;

    const invention = APIService.inventions.find(it => it.id.toString() === this.state.id);
    const inventor = APIService.inventors.find(it => it.invention.includes(this.state.id.toString()));
    const names = inventor.inventors_bio.map(it => it.inventor_name);
        
    return (
      <div className={styles.detailed}>
        <div className={styles.head} style={{backgroundColor: inventor.inventor_color}}>
          <div className={styles.year}>
            In {invention.invention_year}
          </div>

          <div className={styles.left}>
              <img 
                className={styles.texture}
                src={inventor.inventor_texture_image} alt=""
              />
              <img
             className={classnames(styles.photo, {
               [styles.leftside]: inventor.photo_position_side[0] === 'Left',
               [styles.rightside]: inventor.photo_position_side[0] === 'Right',
               [styles.isMobile]: inventor.mirrored_image === null || inventor.mirrored_image.length === 0
              })}
             src={inventor.inventor_photo} 
             alt={inventor.inventors_bio.map(it => it.inventor_name).join(', ')} 
            />
            {(inventor.mirrored_image !== null && inventor.mirrored_image.length !== 0) && 
              <img
               className={classnames(styles.photo, styles.mirrored, styles.isMobile)} 
               src={inventor.mirrored_image} 
               alt={inventor.inventors_bio.map(it => it.inventor_name).join(', ')} 
              />
            }
          </div>

          <div className={styles.right}>
            <h1 
              className={classnames(styles.names, {[styles.long]: inventor.title.rendered.length > 20})} 
              dangerouslySetInnerHTML={{
                __html: ( inventor.inventors_bio.length > 1) ?
                  [names.slice(0, -1).join(',<br/>'), names.slice(-1)[0]].join(names.length < 2 ? '' : '<br/> & ') :
                  inventor.inventors_bio[0].inventor_name.split(' ').join('<br/>')
                }
              }
            />
            <h2 className={styles.inventionTitle}>
              Invented {invention.title.rendered}
            </h2>

            <div className={styles.description} dangerouslySetInnerHTML={{__html: invention.invention_description}} />
          </div>
        </div>

        <div className={styles.middle}>
          <div className={styles.left}>
            {this.state.isScrollVisible && (
              <div className={styles.scrollBar}>
                <div className={styles.arrowTop} onClick={this.scrollUp} >
                  <Picture name="arrow-top"/>
                </div>
                <div className={styles.arrowBottom} onClick={this.scrollDown} >
                  <Picture name="arrow-bottom"/>
                </div>
              </div>
            )}
            <div className={styles.title}>
              Biography
            </div>

            <div className={styles.name} style={{color: inventor.inventor_color}}>
              {inventor.inventors_bio[this.state.bioSlide].inventor_name}
            </div>

            <div id="biography" ref={ref => this.biography = ref} className={styles.invention} dangerouslySetInnerHTML={{__html: inventor.inventors_bio[this.state.bioSlide].inventor_description}} />

            {inventor.inventors_bio.length > 1 && (
              <div className={styles.womenList}>
                {inventor.inventors_bio.map((bio, index) => (
                  <div
                    className={classnames(styles.item, {[styles.current]: index === this.state.bioSlide})}
                    onClick={() => this.slideBio(index, true)}
                    style={{color: inventor.inventor_color}}
                    key={`biography-${index}`}
                  >
                    {bio.inventor_name}
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className={styles.right}>
            <div className={styles.title}>
              Patent
            </div>

            <div className={styles.patentImage}>
              {
                invention.patent_slider.map((img, index) => (
                  <img 
                  className={classnames({
                    [styles.visible]: index === this.state.slide,
                  })}
                  src={img.patent_slide_image}
                  alt={`patent slide-${index}`}
                  key={`slide-${index}`}/>
                ))
              }
            </div>

            <div className={styles.arrows}>
              <div className={styles.arrowLeft} onClick={() => this.slidePatent(-1)} >
                <Picture name="arrow-left"/>
              </div>

              Page {this.state.slide + 1} of {invention.patent_slider.length}

              <div className={styles.arrowRight} onClick={() => this.slidePatent(1)} >
                <Picture name="arrow-right"/>
              </div>
            </div>
          </div>
          {invention.invention_texture && (
              <div className={styles.inventionTexture}>
                <img src={invention.invention_texture} alt="" />
              </div>
          )}
        </div>

        <div className={styles.bottom} style={{backgroundColor: inventor.inventor_color}}>
          <div className={styles.back}>
            <Link to="/dashboard">
              {"< Back"}
            </Link>
          </div>

          <div className={styles.slider}>
            <Slider current={this.state.id} small />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(injector.connect(Detailed, {
  toRender: ['APIService'],
}));
